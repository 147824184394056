/* eslint-disable @typescript-eslint/no-var-requires */
const { store } = require('store/store')
const {
  addNotification,
  dismissNotification
} = require('store/reducers/notifications/notifications.reducer.ts')
const uuidv4 = require('uuid/v4')
const { release } = require('services/sentry')
const { navigate } = require('@reach/router')

window.doseNavigate = navigate

exports.onServiceWorkerUpdateFound = async ({ serviceWorker }) => {
  try {
    console.log('[sw] update', serviceWorker)
    if (!serviceWorker.waiting) return
    console.log('[sw] skip waiting')
    serviceWorker.waiting.postMessage({ type: 'SKIP_WAITING' })
  } catch (err) {
    console.log('[sw] failed to update', err)
  }
}

exports.onServiceWorkerUpdateReady = () => {
  const id = uuidv4()
  window.doseUpdateNotification = id

  store.dispatch(
    addNotification({
      id,
      created: Date.now() + 15 * 60 * 1000,
      expires: Date.now() + 6 * 24 * 60 * 60 * 1000,
      text: `<b>⚡ App updated!</b> See the <a href="javascript:window.doseNavigate('/app/settings/about')">Changelog</a> for more details.`,
      isPrivate: true,
      checks: { version: release }
    })
  )
}

window.doseInstallUpdate = () => {
  if (window.doseUpdateNotification)
    store.dispatch(dismissNotification(window.doseUpdateNotification))
  window.location.reload(true)
}

// disable android install prompt (we have google play!)
window.addEventListener('beforeinstallprompt', e => {
  console.log('prevented install prompt', e)
  e.preventDefault()
  return false
})
