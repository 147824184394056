import uuidv4 from 'uuid/v4'
import { createReducer, createAction } from '@reduxjs/toolkit'
import { Action } from 'store/store'
import {
  Reminders,
  disableReminders,
  enableReminders
} from 'components/App/Settings/Reminders/ReminderOptions/ReminderOptions'

export const SET_SETUP_FINISHED = 'app/setSetupFinished'
export const SET_TOLERATE_DRUGS = 'app/setTolerateDrugs'
export const SET_MEDICATION_USER = 'app/setMedicationUser'
export const SET_PIN_CODE = 'app/setPinCode'
export const SET_LOCKED = 'app/setLocked'
export const SET_THEME = 'app/setTheme'
export const SET_CURRENCY = 'app/setCurrency'
export const SET_WEEK_START = 'app/setWeekStart'
export const SET_VOLUME_UNIT = 'app/setVolumeUnit'
export const ADD_USER_GOAL = 'app/addUserGoal'
export const UPDATE_REMINDERS = 'app/updateReminders'

export const setSetupFinished = createAction<boolean>(SET_SETUP_FINISHED)
export const setTolerateDrugs = createAction<boolean>(SET_TOLERATE_DRUGS)
export const setMedicationUser = createAction<boolean>(SET_MEDICATION_USER)
export const setPinCode = createAction<string | null>(SET_PIN_CODE)
export const setLocked = createAction<boolean>(SET_LOCKED)
export const setTheme = createAction<string>(SET_THEME)
export const setCurrency = createAction<string>(SET_CURRENCY)
export const setWeekStart = createAction<'mon' | 'sun'>(SET_WEEK_START)
export const setVolumeUnit = createAction<VolumeUnit>(SET_VOLUME_UNIT)
export const addUserGoal = createAction<UserGoal>(ADD_USER_GOAL)
export const updateReminders = createAction(
  UPDATE_REMINDERS,
  (payload: Partial<Reminders>) => {
    // make sure to pass enabled: true if enabled!
    if (typeof payload.enabled !== 'undefined' && !payload.enabled)
      disableReminders()
    if (payload.enabled) enableReminders()
    return { payload }
  }
)

export type VolumeUnit = 'ml' | 'oz'
export type UserGoal =
  | 'moderating'
  | 'quitting'
  | 'knowing'
  | 'medication'
  | 'memories'
  | string

interface AppState {
  user: {
    id: string
    since: number
  }
  userGoals: UserGoal[]
  setupFinished: boolean
  tolerateDrugs: boolean
  medicationUser: boolean
  theme: 'light' | 'dark' | 'ssr' | 'default'
  pinCode: string | null
  locked: boolean
  currency: string
  weekStart: 'mon' | 'sun'
  volumeUnit: VolumeUnit
  reminders: Reminders
}

export const initialState: AppState = {
  user: {
    id: uuidv4(),
    since: Date.now()
  },
  userGoals: [],
  setupFinished: false,
  tolerateDrugs: false,
  medicationUser: false,
  theme: 'default',
  pinCode: null,
  locked: false,
  currency: '$',
  weekStart: 'mon',
  volumeUnit: 'ml',
  reminders: {
    enabled: false,
    type: 'daily',
    weekday: 6,
    hour: 21,
    day: 1
  }
}

export const appReducer = createReducer(initialState, {
  [SET_SETUP_FINISHED]: (state, action) => {
    state.setupFinished = action.payload
  },
  [SET_TOLERATE_DRUGS]: (state, action) => {
    state.tolerateDrugs = action.payload
  },
  [SET_MEDICATION_USER]: (state, action) => {
    state.medicationUser = action.payload
  },
  [SET_PIN_CODE]: (state, action) => {
    state.pinCode = action.payload
    state.locked = false
  },
  [SET_LOCKED]: (state, action) => {
    state.locked = action.payload
  },
  [SET_THEME]: (state, action) => {
    state.theme = action.payload
  },
  [SET_CURRENCY]: (state, action) => {
    state.currency = action.payload
  },
  [SET_WEEK_START]: (state, action) => {
    state.weekStart = action.payload
  },
  [SET_VOLUME_UNIT]: (state, action) => {
    state.volumeUnit = action.payload
  },
  [ADD_USER_GOAL]: (state, { payload }: Action<UserGoal>) => {
    if (!state.userGoals) state.userGoals = []
    state.userGoals.push(payload)
  },
  [UPDATE_REMINDERS]: (state, { payload }: Action<Partial<Reminders>>) => {
    state.reminders = { ...state.reminders, ...payload }
  }
})
