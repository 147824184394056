// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-2020-tsx": () => import("./../../src/pages/2020.tsx" /* webpackChunkName: "component---src-pages-2020-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-domain-incident-tsx": () => import("./../../src/pages/domain-incident.tsx" /* webpackChunkName: "component---src-pages-domain-incident-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-iframe-tsx": () => import("./../../src/pages/iframe.tsx" /* webpackChunkName: "component---src-pages-iframe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

