import { BasicSubstance } from '../helpers/BasicSubstance'
import {
  MICROGRAM,
  MILLIGRAM,
  GRAM,
  CART,
  MILLILITER
} from '../helpers/constants'

export const PSYCHEDELICS = '#8e44ad'
export const STIMULANTS = '#e056fd'
export const BENZODIAZEPINES = '#7ed6df'
export const DEPRESSANTS = '#7ed6df'
export const OPIOIDS = '#eb4d4b'
export const DISSOCIANTS = '#303952'

export const BASIC_SUBSTANCES: BasicSubstance[] = [
  /**
   * Psychedelics
   */
  {
    name: 'LSD',
    aliases: ['lsd-25', 'acid', 'lucy'],
    unit: MICROGRAM,
    color: PSYCHEDELICS,
    quickDoses: [{ name: 'LSD Tab', amount: 100 }]
  },
  {
    name: 'Psilocybin',
    aliases: ['psilocybin', 'shrooms', 'mushrooms', 'psylocybin'],
    unit: GRAM,
    color: PSYCHEDELICS,
    quickDoses: [
      { name: 'Gram of Shrooms', amount: 1 },
      { name: 'Microdose', amount: 0.25 }
    ]
  },
  {
    name: 'DMT',
    aliases: ['n,n-dmt', 'dimethyltryptamine'],
    unit: MILLIGRAM,
    color: PSYCHEDELICS,
    quickDoses: [{ name: 'Smoked DMT', amount: 30 }]
  },
  {
    name: '2C-B',
    aliases: ['2cb', 'nexus', 'bees'],
    unit: MILLIGRAM,
    color: PSYCHEDELICS,
    quickDoses: [{ name: '2C-B Pill', amount: 15 }]
  },
  {
    name: 'Salvia',
    aliases: [],
    unit: MILLIGRAM,
    color: PSYCHEDELICS,
    quickDoses: [{ name: 'Smoked Salvia', amount: 10 }]
  },
  /**
   * Stimulants
   */
  {
    name: 'MDMA',
    aliases: ['molly', 'ecstasy', 'adam', 'xtc', 'mandy', 'x'],
    unit: MILLIGRAM,
    color: STIMULANTS,
    quickDoses: [{ name: '1/2 MDMA Pill', amount: 120 }]
  },
  {
    name: 'Cocaine',
    aliases: ['coke'],
    unit: MILLIGRAM,
    color: STIMULANTS,
    quickDoses: [{ name: 'Cocaine Dose', amount: 30 }]
  },
  {
    name: 'Amphetamine',
    aliases: [
      'speed',
      'pepp',
      'amphetamines',
      'amfetamine',
      'amph',
      'adderall'
    ],
    unit: MILLIGRAM,
    color: STIMULANTS,
    quickDoses: [{ name: 'Amphetamine Dose', amount: 20 }]
  },
  {
    name: 'Ritalin',
    aliases: ['mph', 'concerta', 'biphetin'],
    unit: MILLIGRAM,
    color: STIMULANTS,
    quickDoses: [{ name: 'Ritalin Pill', amount: 20 }]
  },
  {
    name: 'Vyvanse',
    aliases: [],
    unit: MILLIGRAM,
    color: STIMULANTS,
    quickDoses: [{ name: 'Vyvanse Pill', amount: 20 }]
  },
  {
    name: 'Mephedrone',
    aliases: ['mefka', 'mateusz', '4mmc'],
    unit: MILLIGRAM,
    color: STIMULANTS,
    quickDoses: [{ name: 'Line', amount: 45 }]
  },
  /**
   * Benzoidiazepines
   */
  {
    name: 'Xanax',
    aliases: ['alprazolam', 'ksalol', 'niravam'],
    unit: MILLIGRAM,
    color: BENZODIAZEPINES,
    quickDoses: [
      { name: 'Xanax Pill', amount: 0.5 },
      { name: 'Xanax Bar', amount: 2 }
    ]
  },
  {
    name: 'Clonazepam',
    aliases: ['klonopin', 'kpin'],
    unit: MILLIGRAM,
    color: BENZODIAZEPINES,
    quickDoses: [{ name: 'Clonazepam Pill', amount: 0.5 }]
  },
  {
    name: 'Diazepam',
    aliases: ['valium'],
    unit: MILLIGRAM,
    color: BENZODIAZEPINES,
    quickDoses: [{ name: 'Valium Pill', amount: 10 }]
  },
  {
    name: 'Etizolam',
    aliases: ['etizest', 'sedekopan', 'zoly', 'towa'],
    unit: MILLIGRAM,
    color: BENZODIAZEPINES,
    quickDoses: [{ name: 'Pill', amount: 0.5 }]
  },
  /**
   * Opioids
   */
  {
    name: 'Kratom',
    unit: GRAM,
    color: '#6ab04c',
    quickDoses: [
      { name: 'Gram', amount: 1 },
      { name: '3 Grams', amount: 3 }
    ]
  },
  {
    name: 'Codeine',
    unit: MILLIGRAM,
    color: OPIOIDS,
    quickDoses: [{ name: 'Codeine Dose', amount: 150 }]
  },
  {
    name: 'Oxycodone',
    aliases: ['oxy', 'oxycontin', 'percocet', 'oxynorm'],
    unit: MILLIGRAM,
    color: OPIOIDS,
    quickDoses: [{ name: 'Oxycodone Pill', amount: 20 }]
  },
  {
    name: 'Fentanyl',
    unit: MICROGRAM,
    color: OPIOIDS,
    quickDoses: [{ name: 'Fentanyl Dose', amount: 5 }]
  },
  {
    name: 'Tramadol',
    aliases: ['ultram', 'ralivia', 'tramal'],
    unit: MILLIGRAM,
    color: OPIOIDS,
    quickDoses: [{ name: 'Tramadol Pill', amount: 50 }]
  },
  /**
   * Dissociants
   */
  {
    name: 'Ketamine',
    aliases: ['ket', 'kitty', 'kittens'],
    unit: MILLIGRAM,
    color: DISSOCIANTS,
    quickDoses: [{ name: 'Ketamine Dose', amount: 20 }]
  },
  {
    name: 'Nitrous',
    aliases: ['laughing gas', 'n20', 'n2o', 'nos', 'nangs'],
    unit: CART,
    color: DISSOCIANTS,
    quickDoses: [
      { name: 'Nitrous Cart', amount: 1 },
      { name: '5 Nitrous Carts', amount: 5 }
    ]
  },
  {
    name: 'DXM',
    aliases: ['dextromethorphan', 'robotussin', 'dex'],
    unit: MILLIGRAM,
    color: DISSOCIANTS,
    quickDoses: [{ name: 'DXM Dose', amount: 150 }]
  },
  /**
   * Depressants
   */
  {
    name: 'Phenibut',
    unit: GRAM,
    color: DEPRESSANTS,
    quickDoses: [{ name: 'Phenibut Dose', amount: 1 }]
  },
  {
    name: 'Zolpidem',
    aliases: ['ambien', 'intermezzo', 'ediuar', 'stilnox'],
    unit: MILLIGRAM,
    color: DEPRESSANTS,
    quickDoses: [{ name: 'Ambien Pill', amount: 5 }]
  },
  {
    name: 'Pregabalin',
    aliases: ['lyrica'],
    unit: MILLIGRAM,
    color: DEPRESSANTS,
    quickDoses: [{ name: 'Pregabalin Dose', amount: 200 }]
  },
  {
    name: 'GHB',
    aliases: ['gbl'],
    unit: MILLILITER,
    color: DEPRESSANTS,
    quickDoses: [{ name: '1ml GHB Dose', amount: 1 }]
  }
]
