import {
  Substance,
  SubstanceState,
  SubstanceUnits,
  SubstanceStatGenerators,
  lastUsed,
  timesUsed,
  generateTotalSpent
} from '../helpers/Substance'
import { MILLIGRAM, HUMAN_UNITS, CONSUMED } from '../helpers/constants'

export const SMOKED = 'Smoked'
export const EDIBLES = 'Edibles'

export class Caffeine extends Substance {
  defaultState: SubstanceState = {
    id: 'caffeine',
    aliases: ['coffee', 'tea'],
    isActive: false,
    isDrug: false,
    name: 'Caffeine',
    color: '#f19066',
    consumptionTypes: [CONSUMED],
    price: 0,
    baseUnit: MILLIGRAM,
    quickDoses: [
      {
        name: 'Coffee',
        type: MILLIGRAM,
        units: {
          [MILLIGRAM]: 100
        }
      },
      {
        name: 'Tea',
        type: SMOKED,
        units: {
          [MILLIGRAM]: 40
        }
      },
      {
        name: 'Caffeine pill',
        type: CONSUMED,
        units: {
          [MILLIGRAM]: 200
        }
      }
    ]
  }

  units: SubstanceUnits = {
    [CONSUMED]: {
      input: [MILLIGRAM],
      output: [MILLIGRAM],
      factory: units => units
    }
  }

  stats: SubstanceStatGenerators = [
    timesUsed,
    lastUsed,
    {
      id: 'substance-consumed-today',
      factory: doses => {
        const today = new Date().toLocaleDateString('ca')

        const total = doses.reduce(
          (acc, dose) =>
            new Date(dose.time).toLocaleDateString('ca') == today
              ? acc + dose.amount * dose.units[MILLIGRAM]
              : acc,
          0
        )
        return total && Math.round(total) + HUMAN_UNITS[MILLIGRAM]
      }
    },
    generateTotalSpent()
  ]
}
