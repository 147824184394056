import { Alcohol } from './default/Alcohol'
import { Substance, SubstanceState } from './helpers/Substance'
import { Cannabis } from './default/Cannabis'
import { generateBasicSubstances } from './helpers/BasicSubstance'
import { BASIC_SUBSTANCES } from './default/basicSubstances'
import { Nicotine } from './default/Nicotine'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { generateCustomSubstance } from './helpers/customSubstance'
import { Caffeine } from './default/Caffeine'

type SubstanceServices = {
  [id: string]: Substance
}

export type SubstancesStates = {
  [id: string]: SubstanceState
}

export const defaultSubstances: SubstanceServices = {
  alcohol: new Alcohol(),
  cannabis: new Cannabis(),
  nicotine: new Nicotine(),
  caffeine: new Caffeine(),
  ...generateBasicSubstances(BASIC_SUBSTANCES)
}

export const getDefaultSubstancesStates = (): SubstancesStates => {
  const states: SubstancesStates = {}
  Object.keys(defaultSubstances).forEach(key => {
    states[key] = defaultSubstances[key].defaultState
  })
  return states
}

/**
 * Substance proxy
 *
 * If substance is not available, it will create a custom
 * instance (based on redux state) and save it for later
 */
export const substances = new Proxy(
  { ...defaultSubstances },
  {
    get: (substances, id: string) => {
      if (id in substances) return substances[id]

      const state = getSubstanceState(id)(
        require('store/store').store.getState()
      )

      if (!state) {
        class FallbackSubstance extends Substance {}
        return new FallbackSubstance()
      }

      const custom = generateCustomSubstance(state)
      substances[id] = custom
      return custom
    }
  }
)
