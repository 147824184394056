import { combineReducers } from 'redux'
import { appReducer } from './app/app.reducer'
import { substancesReducer } from './substances/substances.reducer'
import { dosesReducer } from './doses/doses.reducer'
import { notificationsReducer } from './notifications/notifications.reducer'
import modalsSlice from './modals/modals.slice'

export const rootReducer = combineReducers({
  app: appReducer,
  substances: substancesReducer,
  doses: dosesReducer,
  notifications: notificationsReducer,
  modals: modalsSlice
})
