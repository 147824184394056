import { createReducer, createAction } from '@reduxjs/toolkit'
import { Notification } from 'services/notifications'
import { Action } from 'store/store'

export const ADD_NOTIFICATION = 'notifications/ADD_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'notifications/DISMISS_NOTIFICATION'

export const addNotification = createAction<Notification>(ADD_NOTIFICATION)
export const dismissNotification = createAction<string>(DISMISS_NOTIFICATION)

interface NotificationsState {
  notifications: Notification[]
}

export const initialState: NotificationsState = {
  notifications: []
}

export const notificationsReducer = createReducer(initialState, {
  [ADD_NOTIFICATION]: (state, { payload }: Action<Notification>) => {
    state.notifications.push(payload)
  },
  [DISMISS_NOTIFICATION]: (state, { payload }: Action<string>) => {
    state.notifications[
      state.notifications.findIndex(n => n.id === payload)
    ].isDismissed = true
  }
})
