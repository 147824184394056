import React, { ChangeEvent } from 'react'
import { H2, Text } from 'components/primitives/Text'
import { TextInput } from 'components/primitives/Input'
import { roundToNearestDay, DAY, getWeekEndSun } from 'services/time'
import { useDispatch, useSelector } from 'react-redux'
import { getRemindersData } from 'store/reducers/app/app.selectors'
import { updateReminders } from 'store/reducers/app/app.reducer'
import { isDev } from 'services/device'

type ReminderTime = 'daily' | 'weekly' | 'monthly'

export interface Reminders {
  enabled: boolean
  type: ReminderTime
  day: number // day of month
  weekday: number // 0 - sun, 6 - sat
  hour: number // 0-24
}

export const ReminderOptions: React.FC = () => {
  const dispatch = useDispatch()
  const data = useSelector(getRemindersData)
  const update = (payload: Partial<Reminders>) => {
    dispatch(updateReminders(payload))
    if (data.enabled) enableReminders()
  }

  if (!_getFCMToken() && !isDev())
    return (
      <>
        <Text as='p' textAlign='center' my='big' color='destructive'>
          Looks like you&apos;re using an outdated version. Please update the
          app to set reminders.
        </Text>
      </>
    )

  return (
    <>
      <H2 textAlign='center' color='textAccent1'>
        I want to receive a<br /> notification&nbsp;
        <TextInput
          as='select'
          value={data.type}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            update({ type: e.currentTarget.value as ReminderTime })
          }
        >
          <option value='daily'>every day</option>
          <option value='weekly'>once a week</option>
        </TextInput>
        &nbsp;
        <br />
        {data.type === 'weekly' && (
          <>
            on&nbsp;
            <TextInput
              as='select'
              value={data.weekday}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                update({ weekday: parseInt(e.currentTarget.value) })
              }
            >
              <option value='0'>Sunday</option>
              <option value='1'>Monday</option>
              <option value='2'>Tuesday</option>
              <option value='3'>Wednesday</option>
              <option value='4'>Thursday</option>
              <option value='5'>Friday</option>
              <option value='6'>Saturday</option>
            </TextInput>
            &nbsp;
          </>
        )}
        at&nbsp;
        <TextInput
          as='select'
          value={data.hour}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            update({ hour: parseInt(e.currentTarget.value) })
          }
        >
          {[...new Array(24)].map((_, i) => {
            const date = new Date(roundToNearestDay())
            date.setHours(i)
            const hour = date.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            })

            return (
              <option value={i} key={i}>
                {hour}
              </option>
            )
          })}
        </TextInput>
      </H2>
    </>
  )
}

const _getFCMToken = () => localStorage.getItem('fcm-token')
const _updateReminders = (payload: Partial<Reminders>) =>
  require('store/store').store.dispatch(updateReminders(payload))

export const disableReminders = async () => {
  console.log('disable')
  try {
    await fetch(
      `https://backup.doseapp.io/v1/reminders/${_getFCMToken()}/disable`,
      {
        method: 'POST'
      }
    )
  } catch (err) {
    console.log('Failed to disable reminders', err)
    _updateReminders({ enabled: true })
  }
}

export const _getNextCallTimestamp = (
  data: Reminders,
  today?: number
): number => {
  const _d = new Date(today || Date.now())
  _d.setHours(0)

  if (data.type === 'daily') {
    const d = new Date(roundToNearestDay(_d.getTime()))

    if (new Date(today || Date.now()).getHours() >= data.hour)
      d.setDate(d.getDate() + 1)
    d.setHours(data.hour)

    return d.getTime()
  }

  if (data.type === 'weekly') {
    if (
      _d.getDay() <= data.weekday &&
      new Date(today || Date.now()).getHours() < data.hour
    ) {
      // this week
      _d.setDate(_d.getDate() + (data.weekday - _d.getDay()))
      _d.setHours(data.hour)
      return _d.getTime()
    } else {
      // next week
      const d = new Date(getWeekEndSun(today))
      d.setDate(d.getDate() + data.weekday)
      d.setHours(data.hour)
      return d.getTime()
    }
  }

  return 0
}

export const enableReminders = async () => {
  console.log('enable')
  const data = getRemindersData(require('store/store').store.getState())
  const nextCall = _getNextCallTimestamp(data)
  const cooldown = data.type === 'weekly' ? 7 * DAY : DAY

  console.log('nextCall', nextCall, new Date(nextCall))

  try {
    await fetch(
      `https://backup.doseapp.io/v1/reminders/${_getFCMToken()}/enable/${nextCall}/${cooldown}`,
      { method: 'POST' }
    )
  } catch (err) {
    console.log('Failed to enable reminders', err)
    _updateReminders({ enabled: false })
  }
}
