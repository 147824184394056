import { createReducer, createAction } from '@reduxjs/toolkit'
import { Dose } from 'services/substances/helpers/Substance'
import { Action } from 'store/store'
import { substances } from 'services/substances/substances'
import { VolumeUnit } from '../app/app.reducer'
import { MILLILITER } from 'services/substances/helpers/constants'
import { convertVolume } from 'services/substances/helpers/helpers'

const ADD_DOSE = 'doses/addDose'
const UPDATE_DOSE_NOTE = 'doses/updateDoseNote'
const EDIT_DOSE = 'doses/editDose'
const REMOVE_DOSE = 'doses/removeDose'
const SET_ADD_DOSE_TIME = 'doses/setAddDoseTime'
const SET_ADD_DOSE_AMOUNT = 'doses/setAddDoseAmount'
const UPDATE_DOSES_VOLUME_UNIT = 'doses/updateDosesVolumeUnit'

const transformUnits = (payload: AddDose | EditDose) => {
  payload.dose.units = substances?.[payload.substance]?.transformUnits(
    payload.dose
  )
  return { payload }
}

type AddDose = { substance: string; dose: Dose }
export const addDose = createAction(ADD_DOSE, transformUnits)
type EditDose = { substance: string; dose: Dose }
export const editDose = createAction(EDIT_DOSE, transformUnits)

type UpdateDoseNote = RemoveDose & { note: string }
export const updateDoseNote = createAction<UpdateDoseNote>(UPDATE_DOSE_NOTE)

type RemoveDose = { substance: string; year: number; doseid: string }
export const removeDose = createAction<RemoveDose>(REMOVE_DOSE)

export const setAddDoseTime = createAction<number>(SET_ADD_DOSE_TIME)
export const setAddDoseAmount = createAction<number>(SET_ADD_DOSE_AMOUNT)
export const updateDosesVolumeUnit = createAction<VolumeUnit>(
  UPDATE_DOSES_VOLUME_UNIT
)

type Doses = {
  addDoseTime: number
  addDoseAmount: number
  // Segregate doses by year to avoid big arrays
  [year: number]: {
    [substance: string]: Dose[]
  }
}

export const getYear = (dose: Dose) => new Date(dose.time).getFullYear()

export const initialState: Doses = {
  addDoseTime: 0,
  addDoseAmount: 1,
  [new Date().getFullYear()]: {}
}

const getSubstanceDoses = (
  state: Doses,
  payload: AddDose | EditDose | RemoveDose
) => {
  const year =
    (payload as RemoveDose).year ||
    getYear((payload as AddDose | EditDose).dose)

  if (!state[year]) state[year] = {}
  if (!state[year][payload.substance]) state[year][payload.substance] = []
  return state[year][payload.substance]
}

export const dosesReducer = createReducer(initialState, {
  [ADD_DOSE]: (state, { payload }: Action<AddDose>) => {
    const doses = getSubstanceDoses(state, payload)

    doses.push(payload.dose)
  },
  [UPDATE_DOSE_NOTE]: (state, { payload }: Action<UpdateDoseNote>) => {
    const doses = getSubstanceDoses(state, payload)
    const i = doses.findIndex((dose: Dose) => dose.id === payload.doseid)

    doses[i].note = { text: payload.note, updated: Date.now() }
  },
  [EDIT_DOSE]: (state, { payload }: Action<EditDose>) => {
    const doses = getSubstanceDoses(state, payload)
    const i = doses.findIndex((dose: Dose) => dose.id === payload.dose.id)

    doses[i] = {
      ...doses[i],
      ...payload.dose
    }
  },
  [REMOVE_DOSE]: (state, { payload }: Action<RemoveDose>) => {
    // Adds 'removed: true' instead of deleting the dose because
    // a) It can be reverted
    // b) It's easier to sync across multiple devices
    const doses = getSubstanceDoses(state, payload)
    const i = doses.findIndex((dose: Dose) => dose.id === payload.doseid)
    doses[i].removed = true
  },
  [SET_ADD_DOSE_TIME]: (state, { payload }: Action<number>) => {
    state.addDoseTime = payload
  },
  [SET_ADD_DOSE_AMOUNT]: (state, { payload }: Action<number>) => {
    state.addDoseAmount = payload
  },
  [UPDATE_DOSES_VOLUME_UNIT]: (state, { payload }: Action<VolumeUnit>) => {
    Object.keys(state).forEach(year => {
      const yr = (year as keyof Doses) as number
      if (['addDoseTime', 'addDoseAmount'].includes(year)) return

      Object.keys(state[yr]).forEach(substance => {
        // TODO: speed it up by limiting substances that are looped
        state[yr][substance] = state[yr][substance].map((dose: Dose) => {
          Object.keys(dose.units).forEach(unit => {
            if (unit === MILLILITER)
              dose.units[unit] = convertVolume(
                dose.units[unit],
                payload,
                substance === 'alcohol'
              )
          })
          return dose
        })
      })
    })
  }
})
