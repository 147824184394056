import { DefaultTheme } from 'styled-components'

export const colors = {
  color1: 'var(--color-1)',
  color2: 'var(--color-2)',
  color3: 'var(--color-3)',
  color4: 'var(--color-4)',
  destructive: 'var(--destructive)',

  shadow: 'var(--shadow-color)',
  activeBorderColor: 'var(--active-border-color)',
  inactiveBorderColor: 'var(--inactive-border-color)',

  text: 'var(--text)',
  textAccent1: 'var(--text-accent-1)',
  textAccent2: 'var(--text-accent-2)',

  bg: 'var(--bg)',
  modalBg: 'var(--modalBg)',
  accent1: 'var(--accent-1)',
  accent2: 'var(--accent-2)',
  accent3: 'var(--accent-3)',
  accent4: 'var(--accent-4)'
}

export const space = {
  none: '0',
  tiny: '0.5rem',
  small: '1rem',
  medium: '2rem',
  big: '4rem',

  psmall: '0.5rem 1rem',
  pmedium: '1rem 1.5rem',
  pbig: '2rem'
}

export const shadows = {
  top: `0 -3px 5px ${colors.shadow}`,
  bottom: `0 3px 5px ${colors.shadow}`
}

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700
}

export const fontSizes = {
  small: '1.4rem',
  regular: '1.8rem',
  big: '2.2rem'
}

export const font = 'brandon-grotesque, sans-serif'

export const flexboxgrid = {
  gridSize: 12, // columns
  gutterWidth: 1 * 1.6, // rem
  outerMargin: 2 * 1.6, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46 * 1.6, // rem
    md: 61 * 1.6, // rem
    lg: 62 * 1.6 // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 64, // em
    lg: 70 // em
  }
}

export const baseTheme = {
  colors,
  space,
  shadows,
  fontWeights,
  fontSizes,
  flexboxgrid
}

const brandColors = `
  --color-1: #F8D092;
  --color-2: #E4BA8F;
  --color-3: #D7A480;
  --color-4: #A07D6A;
  --destructive: #e74c3c;
  --transition: 0.3s;  
`

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  id: 'light',
  cssVariables: `:root {
    ${brandColors}
    --active-border-color: #F9E5C6;
    --inactive-border-color: var(--accent-3);
    --shadow-color: rgba(0,0,0,0.1);

    --text: #303030;
    --text-accent-1: #707070;
    --text-accent-2: #a7a7a7;

    --bg: #fff;
    --modalBg: rgba(0, 0, 0, 0.4);
    --accent-1-: #F8FAFC;
    --accent-1: #f2f5f7;
    --accent-2: #f0f0f0;
    --accent-3: #e2e2e2;
    --accent-4: #cfcfcf;

    --card-page-bg: var(--accent-1);
    --card-bg: var(--bg);

    --menu-icon-active: #505050;
  }`
}

export const darkTheme: DefaultTheme = {
  ...baseTheme,
  id: 'dark',
  cssVariables: `:root {
    ${brandColors}
    --active-border-color: #A07D6A;
    --inactive-border-color: #1d1d1d;
    --shadow-color: rgba(0,0,0,0);

    --text: #cfcfcf;
    --text-accent-1: #8f8f8f;
    --text-accent-2: #696969;
    
    --bg: #000;
    --modalBg: rgba(155, 155, 155, 0.4);
    --accent-1-good: #070503;
    --accent-1: #0f0f0f;
    --accent-2: #0f0f0f;
    --accent-3: #1d1d1d;
    --accent-4: #303030;

    --card-page-bg: var(--bg);
    --card-bg: var(--accent-1);

    --menu-icon-active: var(--text);
  }`
}

export const ssrTheme: DefaultTheme = {
  ...baseTheme,
  id: 'ssr',
  cssVariables: `
    ${lightTheme.cssVariables}
    ${darkTheme.cssVariables.replace(':root', '[data-theme="dark"]')}
  `
}

export const themes: { [name: string]: DefaultTheme } = {
  light: lightTheme,
  dark: darkTheme,
  ssr: ssrTheme
}
