import { getCurrency } from 'store/reducers/app/app.selectors'
import { VolumeUnit } from 'store/reducers/app/app.reducer'
import {
  VOLUME_CONVERSION,
  CUSTOM_VOLUME_CONVERSION,
  OUNCE,
  MILLILITER
} from './constants'

export const round = (n: number, to = 100): number => {
  return Math.round(n * to) / to
}

export const currency = (n: number): string => {
  const sign = getCurrency(require('store/store').store.getState())
  const signLeft = ['$'].includes(sign)

  if (isNaN(n)) return 'No data'
  const money = (n / 100).toFixed(2)
  return signLeft ? sign + money : money + sign
}

export const currencyFloat = (n: number): number => n / 100

export const convertVolume = (
  n: number,
  to: VolumeUnit,
  useCustom = false
): number => {
  if (useCustom) {
    const custom = CUSTOM_VOLUME_CONVERSION[to][n]
    if (custom) return custom
  }

  const converted = n * VOLUME_CONVERSION[to]
  const isSmall =
    (to === OUNCE && converted < 1) || (to === MILLILITER && converted < 3)
  return round(converted, isSmall ? 1000 : 10)
}
