import { RootState } from 'store/store'
import { SubstanceState } from 'services/substances/helpers/Substance'
import { CONSUMED } from 'services/substances/helpers/constants'

const fallbackState: SubstanceState = {
  id: '',
  isActive: true,
  name: '',
  color: '#ffffff',
  consumptionTypes: [CONSUMED],
  quickDoses: [],
  baseUnit: 'g'
}

export const getActiveSubstances = (state: RootState) =>
  Object.keys(state.substances).filter(
    substance => state.substances[substance].isActive
  )

export const getSubstancesStates = (state: RootState) =>
  Object.values(state.substances).filter(s => s.id)

export const getActiveSubstancesStates = (state: RootState) =>
  getActiveSubstances(state).map(substance => state.substances[substance])

export const getSubstanceState = (id: string) => (state: RootState) =>
  state.substances[id] || fallbackState

export const getSubstances = (state: RootState) => state.substances

export const getSubstanceIds = (state: RootState) =>
  Object.keys(state.substances)

export const getTakenNames = (state: RootState) =>
  Object.values(state.substances).map(s => s.name)
