import { RootState } from 'store/store'

export const getIsSetupFinished = (state: RootState) => state.app.setupFinished
export const getToleratesDrugs = (state: RootState) => state.app.tolerateDrugs
export const getUser = (state: RootState) => state.app.user
export const getIsLocked = (state: RootState) =>
  state.app.pinCode ? state.app.locked : false
export const getPinCode = (state: RootState) => state.app.pinCode
export const getTheme = (state: RootState) => state.app.theme
export const getCurrency = (state: RootState) => state.app.currency
export const getWeekStart = (state: RootState) => state.app.weekStart
export const getVolumeUnit = (state: RootState) => state.app.volumeUnit
export const getIsUsingOz = (state: RootState) => state.app.volumeUnit === 'oz'
export const getUsedTheme = (state: RootState) => {
  let theme = state.app.theme
  if (theme === 'ssr' || theme === 'default')
    theme =
      typeof window === 'undefined'
        ? 'light'
        : window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
  return theme
}
export const getRemindersData = (state: RootState) => state.app.reminders
export const getUserGoals = (state: RootState) => state.app.userGoals
