import styled, { css } from 'styled-components'
import {
  typography,
  TypographyProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  flexbox,
  FlexboxProps
} from 'styled-system'

type TextCSSProps = TypographyProps & ColorProps & SpaceProps & FlexboxProps

const TextCSS = css<TextCSSProps>`
  ${typography}
  ${color}
  ${space}
  ${flexbox}
`

export const textColor = {
  default: 'var(--text)',
  accent1: 'var(--text-accent-1)',
  accent2: 'var(--text-accent-2)'
}

export const textSize = {
  regular: '1.8rem',
  small: '1.4rem',
  big: '2.2rem'
}

export const P = styled.p`
  ${TextCSS}
`

export const Text = styled.span`
  ${TextCSS}
`

export const H1 = styled.h1`
  ${TextCSS}
`

H1.defaultProps = {
  fontWeight: 'medium',
  fontSize: '3.2rem',
  lineHeight: '1.3em'
}

export const H2 = styled.h2`
  ${TextCSS}
`

H2.defaultProps = {
  fontWeight: 'medium',
  fontSize: '2.6rem'
}
