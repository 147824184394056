// This module persists and loads redux state from localStorage
//
// Only properties different from initial are saved

import { rootReducer } from './reducers/root.reducer'
import deepMerge from 'deepmerge'
import { RootState } from './store'
import { navigate } from '@reach/router'

export const STORAGE = 'state'
export const STORAGE_WIPED = 'state_wiped'
export const FCM_TOKEN = 'fcm-token'
export const initialState = rootReducer(undefined, { type: null })

type Obj = { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any

export const _difference = (a: Obj, b: Obj) => {
  const diff: Obj = {}
  Object.keys(a).forEach(key => {
    if (a[key] === b[key]) return

    diff[key] =
      typeof a[key] === 'object' && !(a[key] instanceof Array)
        ? _difference(a[key], b[key] || {})
        : a[key]
  })
  return diff
}

const tryIframeLoading = (): Promise<boolean> => {
  return new Promise(resolve => {
    const end = (result: boolean) => {
      if (resolved) return
      resolved = true
      window.removeEventListener('message', messageHandler, false)
      resolve(result)
    }

    console.log('[data] trying to load data from iframe')
    let resolved = false

    const newIframe = document.createElement('iframe')
    newIframe.setAttribute('width', '1')
    newIframe.setAttribute('height', '1')
    newIframe.setAttribute('src', 'https://doseapp.io/iframe')
    newIframe.setAttribute('style', 'border: none; opacity: 0.01;')
    newIframe.setAttribute('id', 'dose-iframe')
    document.body.appendChild(newIframe)

    const iframe = document.getElementById('dose-iframe') as HTMLIFrameElement
    if (!iframe) return console.log("[data] couldn't find iframe")

    iframe?.contentWindow?.postMessage({ action: 'get', key: 'state' }, '*')

    const messageHandler = (event: MessageEvent) => {
      const { action, /*key,*/ value } = event.data
      if (action == 'returnData') {
        console.log('[data] received data', value)
        navigate('/app/loading')
        require('./store').store.dispatch({
          type: 'SET_APP_STATE',
          payload: value
        })
        window.location.pathname = '/app'
        end(true)
      }
    }

    window.addEventListener('message', messageHandler, false)

    setTimeout(() => end(false), 5000)
  })
}

export const load = () => {
  if (typeof window === 'undefined') return initialState // SSR

  const fromStorage = window.localStorage.getItem(STORAGE)
  const persisted = JSON.parse(fromStorage || '{}')

  if (!fromStorage || fromStorage.length < 1500) {
    setImmediate(tryIframeLoading)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const overwriteMerge = (destination: any[], source: any[]) =>
    source.length ? source : destination

  const merged: RootState = deepMerge(initialState, persisted, {
    arrayMerge: overwriteMerge
  })

  //merged.app.pinCode = 1234
  //merged.app.pinCode = null
  merged.app.locked = true

  //if (merged?.substances?.psilocybin) {
  //  delete merged.substances.psilocybin
  //}

  return merged
}

export const getUserData = () => {
  const state = require('./store').store.getState()
  const diff = _difference(state, initialState)

  diff.app.user = state.app.user
  if (diff.app.setupFinished !== false) diff.app.setupFinished = true

  return diff
}

export const persist = () => {
  const diff = getUserData()

  if (typeof window === 'undefined') return diff // SSR
  window.localStorage.setItem(STORAGE, JSON.stringify(diff))
  return diff
}
