import styled from 'styled-components'
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system'

const center = `justify-content: center;`

export const View = styled.div<FlexboxProps & SpaceProps>`
  ${flexbox}
  ${space}
  display: flex;
`

export const ColumnView = styled(View)`
  flex-direction: column;
`

export const RowView = styled(View)`
  flex-direction: row;
`

export const CenteredRowView = styled(RowView)`
  ${center}
`

export const CenteredColumnView = styled(ColumnView)`
  ${center}
`

export const FullCenterView = styled(View)`
  ${center}
  align-items: center;
  height: 100%;
  text-align: center;
`
