import * as Sentry from '@sentry/browser'
import { version } from '../../package.json'

export const release = version

Sentry.init({
  dsn: process.env.GATSBY_SENTRY,
  environment: process.env.NODE_ENV,
  release
})
