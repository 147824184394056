import { createReducer, createAction } from '@reduxjs/toolkit'
import {
  SubstanceState,
  QuickDose
} from 'services/substances/helpers/Substance'
import { VolumeUnit } from '../app/app.reducer'
import { MILLILITER } from 'services/substances/helpers/constants'
import { Action } from 'store/store'
import { convertVolume } from 'services/substances/helpers/helpers'
import { getDefaultSubstancesStates } from 'services/substances/substances'

const ADD_SUBSTANCE = 'substances/addSubstance'
const UPDATE_SUBSTANCE = 'substances/updateSubstance'
const DELETE_SUBSTANCE = 'substances/deleteSubstance'
export const SET_SUBSTANCE_ACTIVE = 'substances/setSubstanceActive'
const ADD_QUICK_DOSE = 'substances/addQuickDose'
const DELETE_QUICK_DOSE = 'substances/deleteQuickDose'
const UPDATE_QUICK_VOLUME_UNIT = 'substances/updateQuickVolumeUnit'

export const addSubstance = createAction<SubstanceState>(ADD_SUBSTANCE)

export const updateSubstance = createAction<Partial<SubstanceState>>(
  UPDATE_SUBSTANCE
)

export const deleteSubstance = createAction<string>(DELETE_SUBSTANCE)

type SetActive = { id: string; value: boolean }
export const setSubstanceActive = createAction<SetActive>(SET_SUBSTANCE_ACTIVE)

type AddQuickDose = { id: string; dose: QuickDose }
export const addQuickDose = createAction<AddQuickDose>(ADD_QUICK_DOSE)

type DeleteQuickDose = { id: string; name: string }
export const deleteQuickDose = createAction<DeleteQuickDose>(DELETE_QUICK_DOSE)

export const updateQuickVolumeUnit = createAction<VolumeUnit>(
  UPDATE_QUICK_VOLUME_UNIT
)

export const initialState = getDefaultSubstancesStates()

export const substancesReducer = createReducer(initialState, {
  [ADD_SUBSTANCE]: (state, action) => {
    state[action.payload.id] = action.payload
  },
  [UPDATE_SUBSTANCE]: (state, action) => {
    state[action.payload.id] = {
      ...state[action.payload.id],
      ...action.payload
    }
  },
  [DELETE_SUBSTANCE]: (state, action) => {
    delete state[action.payload]
  },
  [SET_SUBSTANCE_ACTIVE]: (state, action) => {
    state[action.payload.id].isActive = action.payload.value
    state[action.payload.id].lastTouched = Date.now()
  },
  [ADD_QUICK_DOSE]: (state, action) => {
    state[action.payload.id].quickDoses.push(action.payload.dose)
  },
  [DELETE_QUICK_DOSE]: (state, action) => {
    state[action.payload.id].quickDoses = state[
      action.payload.id
    ].quickDoses.filter(dose => dose.name !== action.payload.name)
  },
  [UPDATE_QUICK_VOLUME_UNIT]: (state, { payload }: Action<VolumeUnit>) => {
    Object.keys(state).forEach(substance => {
      state[substance].quickDoses = state[substance].quickDoses.map(quick => {
        if (Object.keys(quick.units).includes(MILLILITER))
          quick.units[MILLILITER] = convertVolume(
            quick.units[MILLILITER],
            payload,
            substance === 'alcohol'
          )
        return quick
      })
    })
  }
})
