import moment from 'moment'

export type Millis = number

export const MINUTE = 60 * 1000
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

export const getYears = (from: Millis, to: Millis): number[] => {
  const fromYear = new Date(from).getFullYear()
  const toYear = new Date(to).getFullYear()

  if (fromYear === toYear) return [fromYear]
  if (fromYear === toYear - 1) return [fromYear, toYear]
  return [...new Array(toYear - fromYear + 1)].map((_, i) => fromYear + i)
}

export const lastYear = () => {
  const d = new Date().getFullYear()
  return [d, d - 1]
}

export const getTodayMillis = (today?: Millis): Millis => {
  const d = new Date(today || Date.now())
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d.getTime()
}

export const recentYears = (years = 5): number[] => {
  return [...new Array(years)].map((_, i) => new Date().getFullYear() - i)
}

export const roundToNearestDay = (time?: Millis): Millis => {
  const d = new Date(time || Date.now())
  const h = d.getHours()
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  if (h < 12) d.setHours(0)
  else d.setTime(d.getTime() + (24 - h) * HOUR)
  return d.getTime()
}

export const getWeekEnd = (today?: Millis): Millis => {
  const weekDay = new Date(today || Date.now()).getDay()
  return roundToNearestDay(getTodayMillis(today) + (7 - weekDay) * DAY)
}

export const getWeekEndSun = (today?: Millis): Millis => {
  const weekDay = new Date(today || Date.now()).getDay()
  return roundToNearestDay(
    getTodayMillis(today) + (weekDay === 0 ? 0 : 7 - weekDay) * DAY
  )
}

export const daysAgo = (millis: Millis): number => {
  const diff = Date.now() - millis
  if (Math.abs(diff) < 12 * HOUR) return 0
  return Math.round(diff / DAY)
}

export const fromNow = (millis: Millis): string => moment(millis).fromNow()

export const daysFromNow = (millis: Millis): string =>
  Date.now() - millis < DAY ? 'Today' : moment(millis).fromNow()

export const calendarTime = (millis: Millis): string =>
  moment(millis).calendar()

export const exactHour = (millis: Millis): string =>
  moment(millis).format('h:mm a')

// {M-1}
export const getMonthId = (millis?: Millis) =>
  new Date(millis || Date.now()).getMonth()

// {M-1}-{YYYY}
export const getCalendarId = (millis?: Millis) =>
  getMonthId(millis) + '-' + new Date(millis || Date.now()).getFullYear()

export const parseCalendarId = (id: string) =>
  id.replace('#', '').split('-') as [string, string | undefined]

export const getDaysInMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), 0).getDate()
