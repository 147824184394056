import { VolumeUnit } from 'store/reducers/app/app.reducer'
import { getIsUsingOz } from 'store/reducers/app/app.selectors'

export const CONSUMED = 'consumed'

export const MICROGRAM = 'ug'
export const MILLIGRAM = 'mg'
export const GRAM = 'g'
export const MILLILITER = 'ml'
export const OUNCE = 'oz' // only used in conversions

export const PERCENT = 'perc'
export const CART = 'cart'

export const GENERIC_UNITS = [CART, MICROGRAM, MILLIGRAM, GRAM, MILLILITER]

export const HUMAN_UNITS: { [unit: string]: string } = {
  [MICROGRAM]: 'µg',
  [MILLIGRAM]: MILLIGRAM,
  [GRAM]: GRAM,
  get ml () {
    return getIsUsingOz(require('store/store').store.getState())
      ? OUNCE
      : MILLILITER
  },
  [PERCENT]: '%',
  [CART]: 'x'
}

export const BULK_UNIT: { [unit: string]: string } = {
  [MICROGRAM]: MILLIGRAM,
  [MILLIGRAM]: GRAM,
  [GRAM]: GRAM,
  [CART]: CART,
  [MILLILITER]: MILLILITER
}

export const BULK_MULTIPLIER: { [unit: string]: number } = {
  [MICROGRAM]: 1 / 1000,
  [MILLIGRAM]: 1 / 1000,
  [GRAM]: 1,
  [CART]: 1,
  [MILLILITER]: 1
}

const _OUNCE = 29.5735296
export const VOLUME_CONVERSION = {
  [MILLILITER]: _OUNCE, // oz => ml
  [OUNCE]: 1 / _OUNCE // ml => oz
}

export const CUSTOM_VOLUME_CONVERSION: {
  [u in VolumeUnit]: { [f: number]: number }
} = {
  [MILLILITER]: {
    1.5: 50,
    5: 100,
    12: 500
  },
  [OUNCE]: {
    50: 1.5,
    100: 5,
    500: 12
  }
}
