import { Substance, timesUsed, lastUsed, generateTotalSpent } from './Substance'
import { CONSUMED, HUMAN_UNITS } from './constants'

// Generate Substance instances for substances that
// don't require custom statistics or dosages

export interface BasicSubstance {
  name: string
  unit: string
  quickDoses: {
    name?: string
    amount: number
  }[]
  color?: string
  isDrug?: boolean
  aliases?: string[]
}

class Basic extends Substance {}

export const generateBasicSubstance = (substance: BasicSubstance) => {
  const instance = new Basic()
  const id = substance.name.toLowerCase()

  const quickDoses = substance.quickDoses.map(quick => ({
    name: quick.name || 'Dose',
    type: CONSUMED,
    units: {
      [substance.unit]: quick.amount
    }
  }))

  instance.defaultState = {
    ...instance.defaultState,
    id,
    name: substance.name,
    color: substance.color || 'var(--color1)',
    isDrug: typeof substance.isDrug !== 'undefined' ? substance.isDrug : true,
    quickDoses,
    aliases: substance.aliases,
    baseUnit: substance.unit
  }

  instance.units = {
    [CONSUMED]: {
      input: [substance.unit],
      output: [substance.unit],
      factory: units => units
    }
  }

  instance.stats = [
    {
      id: 'substance-consumed',
      factory: (_, summary) =>
        summary[substance.unit] &&
        summary[substance.unit] + HUMAN_UNITS[substance.unit]
    },
    timesUsed,
    lastUsed,
    generateTotalSpent()
  ]

  return { [id]: instance }
}

export const generateBasicSubstances = (substances: BasicSubstance[]) => {
  return substances
    .map(generateBasicSubstance)
    .reduce((acc, prev) => ({ ...acc, ...prev }))
}
