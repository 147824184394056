import { CONSUMED, MILLIGRAM, HUMAN_UNITS, BULK_MULTIPLIER } from './constants'
import { fromNow, Millis } from 'services/time'
import History from 'assets/icons/fa/history-duotone.svg'
import ChevronUp from 'assets/icons/fa/chevron-up-duotone.svg'
import Dollar from 'assets/icons/fa/badge-dollar-duotone.svg'
//import { currency } from './helpers'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { daysAgo } from '../../time'

export type SubstanceState = {
  id: string
  isActive: boolean
  name: string
  color: string
  consumptionTypes: string[]
  quickDoses: QuickDose[]
  isDrug?: boolean
  lastTouched?: Millis
  aliases?: string[]
  price?: number
  isPricePerDose?: boolean
  baseUnit: string
  _custom?: {
    units?: SubstanceUnits
    stats?: SubstanceStatGenerators
  }
}

export type SubstanceUnits = {
  [type: string]: {
    input: string[]
    output: string[]
    factory: (units: DoseUnits) => DoseUnits
  }
}

export type SubstanceStatGenerator = {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  id: string
  factory: (
    doses: Dose[],
    summary: DosesSummary,
    state: SubstanceState
  ) => string | number | null
}

export type SubstanceStatGenerators = SubstanceStatGenerator[]

export type SubstanceStat = {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  id: string
  value: string | number | null
}

export type QuickDose = {
  name: string
  type: DoseType
  units: DoseUnits
  customCost?: number
}

export type Dose = QuickDose & {
  id: string
  time: number
  amount: number
  removed?: boolean
  note?: {
    text: string
    updated: number
  }
}

export type DoseType = 'consumed' | string

export type DosesSummary = {
  [type: string]: number
}

export type DoseUnits = {
  [type: string]: number
}

export const lastUsed: SubstanceStatGenerator = {
  icon: History,
  id: 'substance-last-use',
  factory: doses => {
    if (!doses.length) return null
    const dose = doses
      .sort((a, b) => b.time - a.time)
      .filter(d => !d.removed)[0]

    const days = daysAgo(dose.time)
    if (Math.abs(days) < 7) return fromNow(dose.time)
    if (days < 0) return `in ${Math.abs(days)} days`
    return `${days}d ago`
  }
}

export const timesUsed: SubstanceStatGenerator = {
  icon: ChevronUp,
  id: 'substance-times-used',
  factory: doses => doses.length
}

export const generateTotalSpent = (
  calculateUnit?: (dose: Dose, state: SubstanceState) => number
): SubstanceStatGenerator => {
  return {
    icon: Dollar,
    id: 'substance-total-spent',
    factory: (doses, summary, state) => {
      const unitFactory =
        calculateUnit || ((dose, state) => dose.units[state.baseUnit])

      const costs = doses.map(dose => {
        if (dose.customCost) return dose.customCost

        if (state.isPricePerDose) return dose.amount * (state.price || 0)

        return (
          unitFactory(dose, state) *
          (state.price || 0) *
          BULK_MULTIPLIER[state.baseUnit] *
          dose.amount
        )
      })
      console.log('costs', costs)
      const total = costs.reduce((a, b) => a + b, 0)

      if (total === 0 && !state.price) return null

      if (summary.asFloat) return require('./helpers').currencyFloat(total)
      return require('./helpers').currency(total)
    }
  }
}

export abstract class Substance {
  defaultState: SubstanceState = {
    id: 'substance',
    aliases: [],
    isActive: false,
    name: 'Substance',
    color: 'var(--green)',
    consumptionTypes: [CONSUMED],
    baseUnit: MILLIGRAM,
    quickDoses: [
      {
        name: 'One dose',
        type: CONSUMED,
        units: {
          [MILLIGRAM]: 1
        }
      }
    ]
  }

  get state (): SubstanceState {
    return (
      getSubstanceState(this.defaultState.id)(
        require('store/store').store.getState()
      ) || this.defaultState
    )
  }

  units: SubstanceUnits = {
    [CONSUMED]: {
      input: [MILLIGRAM],
      output: [MILLIGRAM],
      factory: units => units
    }
  }

  stats: SubstanceStatGenerators = [
    {
      id: 'substance-consumed',
      factory: (_, summary) =>
        summary[MILLIGRAM] && summary[MILLIGRAM] + HUMAN_UNITS[MILLIGRAM]
    },
    timesUsed,
    lastUsed,
    generateTotalSpent()
  ]

  transformUnits (dose: Dose): DoseUnits {
    return {
      ...dose.units,
      ...this.units[dose.type].factory(dose.units)
    }
  }

  generateStats (doses: Dose[], state?: SubstanceState): SubstanceStat[] {
    if (!state) state = this.defaultState
    const summary = this._getConsumptionSummary(doses)

    return this.stats.map(stat => ({
      id: stat.id,
      icon: stat.icon,
      value: stat.factory(doses, summary, state as SubstanceState)
    }))
  }

  /**
   * Get total consumed amount from an array of doses
   */
  private _getConsumptionSummary (doses: Dose[]): DoseUnits {
    return doses.reduce((accumulator: DoseUnits, dose) => {
      Object.keys(dose.units).forEach(unit => {
        accumulator[unit] =
          (accumulator[unit] || 0) + dose.units[unit] * dose.amount
      })

      return accumulator
    }, {})
  }
}
