import React from 'react'
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook
} from 'react-redux'
import {
  configureStore,
  getDefaultMiddleware,
  AnyAction
} from '@reduxjs/toolkit'
import { rootReducer } from './reducers/root.reducer'
import { load, persist } from './persist'
import { useState } from 'react'
import { SubstancesStates } from 'services/substances/substances'
import debounce from 'lodash.debounce'

export const SET_APP_STATE = 'SET_APP_STATE'
export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === SET_APP_STATE) {
      state = action.payload
    }

    return rootReducer(state, action)
  },
  preloadedState: load(),
  middleware: [...getDefaultMiddleware()] as const,
  devTools: process.env.NODE_ENV !== 'production'
})

export default store

export const multi = (actions: AnyAction[]) => actions.map(store.dispatch)

store.subscribe(debounce(persist, 250))

export type RootState = ReturnType<typeof rootReducer>
export type Action<T> = { type: string; payload: T }
export type AppDispatch = typeof store.dispatch

export const getInitState = () => {
  const removeColors = (substances: SubstancesStates) => {
    const s: SubstancesStates = {}
    Object.keys(substances).map(id => {
      s[id] = { ...substances[id], color: 'var(--accent-2)' }
    })
    return s
  }

  const defaultState = rootReducer(undefined, { type: '' })
  const state: RootState = {
    ...defaultState,
    app: { ...defaultState.app, theme: 'ssr', setupFinished: true },
    substances: removeColors(defaultState.substances)
  }
  return state
}

export const useSelector: TypedUseSelectorHook<RootState> = selector => {
  const [mounted, setMounted] = useState(false)
  React.useEffect(() => setMounted(true), [])

  const selected = useReduxSelector(selector)
  const state = getInitState()

  return mounted ? selected : selector(state)
}
