import {
  SubstanceState,
  Substance,
  timesUsed,
  lastUsed,
  generateTotalSpent
} from './Substance'
import { CONSUMED, HUMAN_UNITS } from './constants'
import uuidv4 from 'uuid/v4'
import { round } from './helpers'

class Custom extends Substance {}

export const generateCustomSubstance = (state: SubstanceState): Substance => {
  const instance = new Custom()

  instance.defaultState = {
    ...instance.defaultState,
    ...state
  }

  if (state._custom) {
    if (state._custom.units) instance.units = state._custom.units
    if (state._custom.stats) instance.stats = state._custom.stats
  }

  if (!state._custom?.units)
    instance.units = {
      [CONSUMED]: {
        input: [state.baseUnit],
        output: [state.baseUnit],
        factory: units => units
      }
    }

  if (!state._custom?.stats)
    instance.stats = [
      {
        id: 'substance-consumed',
        factory: (_, summary) =>
          summary[state.baseUnit] &&
          round(summary[state.baseUnit], 100) + HUMAN_UNITS[state.baseUnit]
      },
      timesUsed,
      lastUsed,
      generateTotalSpent()
    ]

  return instance
}

export const generateSubstanceId = (
  name: string | undefined,
  reserved: string[],
  retry = 0
): string => {
  const extra = retry ? `_${retry}` : ''
  const id = `custom:${(name || uuidv4()).toLowerCase()}${extra}`

  if (reserved.includes(id))
    return generateSubstanceId(name, reserved, retry + 1)
  return id
}

export const substanceFromDoseId = (id: string) => {
  const parts = id.split(':')
  if (parts[0] === 'custom') return `custom:${parts[1]}`
  return parts[0]
}

export const generateCustomSubstanceState = (
  state: Partial<SubstanceState>,
  reservedIds: string[]
): SubstanceState => ({
  ...new Custom().defaultState,
  id: generateSubstanceId(state.name, reservedIds),
  quickDoses: [],
  isActive: true,
  ...state
})
