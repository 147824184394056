export const isIOS = () =>
  typeof navigator !== 'undefined' &&
  !!navigator.platform &&
  /iPad|iPhone|iPod/.test(navigator.platform)

export const isAndroid = () =>
  typeof navigator !== 'undefined' &&
  navigator.userAgent &&
  navigator.userAgent.toLowerCase().includes('android')

export const isShorterThan = (px: number) =>
  typeof window !== 'undefined' && window.innerHeight <= px

export const isTiny = () => isShorterThan(600)
export const isSmall = () => isShorterThan(700)

export const isSSR = () => typeof window === 'undefined'
export const isDev = () => process.env.NODE_ENV === 'development'

export const getSystem = () => {
  if (isIOS()) return 'ios'
  if (isAndroid()) return 'android'
  return 'web'
}
