import {
  Substance,
  SubstanceState,
  SubstanceUnits,
  SubstanceStatGenerators,
  lastUsed,
  generateTotalSpent
} from '../helpers/Substance'
import {
  CONSUMED,
  MILLILITER,
  PERCENT,
  GRAM,
  HUMAN_UNITS,
  VOLUME_CONVERSION
} from '../helpers/constants'
import { round } from '../helpers/helpers'
import Cocktail from 'assets/icons/fa/cocktail-duotone.svg'
import WineBottle from 'assets/icons/fa/wine-bottle-regular.svg'
import { getIsUsingOz } from 'store/reducers/app/app.selectors'

// ml => oz
const convertOz = () =>
  getIsUsingOz(require('store/store').store.getState())
    ? VOLUME_CONVERSION[MILLILITER]
    : 1

export class Alcohol extends Substance {
  defaultState: SubstanceState = {
    id: 'alcohol',
    aliases: ['etoh', 'beer', 'ethanol', 'booze', 'hooch'],
    isActive: false,
    name: 'Alcohol',
    color: '#7ed6df',
    consumptionTypes: [CONSUMED],
    baseUnit: GRAM,
    quickDoses: [
      {
        name: 'Glass of Wine',
        type: CONSUMED,
        units: {
          [MILLILITER]: 100,
          [PERCENT]: 15
        }
      },
      {
        name: 'Glass of Beer',
        type: CONSUMED,
        units: {
          [MILLILITER]: 500,
          [PERCENT]: 5
        }
      },
      {
        name: 'Vodka shot',
        type: CONSUMED,
        units: {
          [MILLILITER]: 50,
          [PERCENT]: 40
        }
      }
    ]
  }

  units: SubstanceUnits = {
    [CONSUMED]: {
      input: [MILLILITER, PERCENT],
      output: [GRAM],
      factory: units => ({
        [GRAM]: (units[MILLILITER] * units[PERCENT]) / 100
      })
    }
  }

  stats: SubstanceStatGenerators = [
    {
      icon: WineBottle,
      id: 'alcohol-ml-consumed',
      factory: (_, summary) =>
        summary[MILLILITER] &&
        round(summary[MILLILITER], 1) + HUMAN_UNITS[MILLILITER]
    },
    {
      icon: Cocktail,
      id: 'alcohol-drinks-consumed',
      factory: doses =>
        doses.length &&
        doses.reduce((acc, dose) => acc + dose.amount, 0) + ' drinks'
    },
    lastUsed,
    generateTotalSpent(
      dose => (dose.units[MILLILITER] * dose.units[PERCENT] * convertOz()) / 100
    )
  ]
}
