import React from 'react'
import styled from 'styled-components'
import { RowView } from './Views'
import { colors, font, fontSizes } from 'services/theme'
import { Text } from './Text'

export const TextInput = styled.input`
  background: transparent;
  border: none;
  border-bottom: 5px solid ${colors.inactiveBorderColor};
  color: ${colors.text};
  flex: 1;
  font-family: ${font};
  font-size: 1.8rem;
  font-weight: 700;
  min-width: 3rem;
  text-align: center;

  :focus {
    border-bottom-color: ${colors.activeBorderColor};
    outline: none;
  }
`

export const Input = styled(RowView)`
  font-size: 2.2rem;
  font-weight: 700;
`

export const Error = styled(Text)`
  color: var(--destructive);
  font-size: ${fontSizes.small};
`

export const FieldError = () => <Error>This field is invalid</Error>

export const Textarea = styled.textarea`
  background: transparent;
  border: none;
  border-bottom: 5px solid ${colors.inactiveBorderColor};
  color: ${colors.text};
  flex: 1;
  font-family: ${font};
  font-size: 1.8rem;
  font-weight: 400;
  min-height: 15rem;

  :focus {
    border-bottom-color: ${colors.activeBorderColor};
    outline: none;
  }
`
