import {
  Substance,
  SubstanceState,
  SubstanceUnits,
  SubstanceStatGenerators,
  lastUsed,
  timesUsed,
  generateTotalSpent
} from '../helpers/Substance'
import {
  GRAM,
  MILLIGRAM,
  HUMAN_UNITS,
  BULK_MULTIPLIER
} from '../helpers/constants'
import Bong from 'assets/icons/fa/bong-duotone.svg'
import CannabisSVG from 'assets/icons/fa/cannabis-duotone.svg'
import { round } from '../helpers/helpers'

export const SMOKED = 'Smoked'
export const EDIBLES = 'Edibles'

export class Cannabis extends Substance {
  defaultState: SubstanceState = {
    id: 'cannabis',
    aliases: ['weed', 'thc', 'marijuana', 'dagga', 'hash', 'kannabinoids'],
    isActive: false,
    name: 'Cannabis',
    color: '#badc58',
    consumptionTypes: [SMOKED, EDIBLES],
    price: 0,
    baseUnit: GRAM,
    quickDoses: [
      {
        name: 'Joint',
        type: SMOKED,
        units: {
          [GRAM]: 0.3
        }
      },
      {
        name: 'Bowl',
        type: SMOKED,
        units: {
          [GRAM]: 0.2
        }
      },
      {
        name: 'Bong rip',
        type: SMOKED,
        units: {
          [GRAM]: 0.1
        }
      },
      {
        name: 'Edible',
        type: EDIBLES,
        units: {
          [MILLIGRAM]: 50
        }
      }
    ]
  }

  units: SubstanceUnits = {
    [SMOKED]: {
      input: [GRAM],
      output: [GRAM],
      factory: units => units
    },
    [EDIBLES]: {
      input: [MILLIGRAM],
      output: [MILLIGRAM],
      factory: units => units
    }
  }

  stats: SubstanceStatGenerators = [
    {
      icon: Bong,
      id: 'cannabis-smoked-grams',
      factory: (_, summary) =>
        summary[GRAM] && round(summary[GRAM], 100) + HUMAN_UNITS[GRAM]
    },
    {
      icon: CannabisSVG,
      id: 'cannabis-edibles-milligrams',
      factory: (_, summary) =>
        summary[MILLIGRAM] &&
        round(summary[MILLIGRAM], 10) + HUMAN_UNITS[MILLIGRAM]
    },
    timesUsed,
    lastUsed,
    generateTotalSpent(
      dose =>
        (dose.units[GRAM] || 0) +
        (dose.units[MILLIGRAM] || 0) * BULK_MULTIPLIER[MILLIGRAM]
    )
  ]
}
