import {
  Substance,
  SubstanceState,
  SubstanceUnits,
  SubstanceStatGenerators,
  generateTotalSpent,
  SubstanceStatGenerator
} from '../helpers/Substance'
import { MILLIGRAM, HUMAN_UNITS } from '../helpers/constants'
import Cigarette from 'assets/icons/fa/smoking-duotone.svg'
import Tint from 'assets/icons/fa/tint-duotone.svg'
import ChevronUp from 'assets/icons/fa/chevron-up-duotone.svg'
import { round } from '../helpers/helpers'

export const TOBACCO = 'Tobacco'
export const SMOKED = 'Smoked'
export const VAPED = 'Vaped'

export const MILLIGRAM_SMOKED = 'mgsmoked'
export const MILLIGRAM_VAPED = 'mgvaped'

export class Nicotine extends Substance {
  defaultState: SubstanceState = {
    id: 'nicotine',
    isActive: false,
    isDrug: false,
    name: 'Nicotine',
    aliases: ['cigarettes', 'vape', 'vaping', 'snuff', 'tobacco'],
    color: '#cd6133',
    consumptionTypes: [TOBACCO, VAPED],
    baseUnit: MILLIGRAM,
    quickDoses: [
      {
        name: 'Cigarette',
        type: TOBACCO,
        units: { [MILLIGRAM]: 1 }
      },
      {
        name: 'Cigar',
        type: TOBACCO,
        units: { [MILLIGRAM]: 15 }
      },
      {
        name: '1g of Snus',
        type: TOBACCO,
        units: { [MILLIGRAM]: 8 }
      },
      {
        name: '3% Juul pod',
        type: VAPED,
        units: { [MILLIGRAM]: 23 }
      },
      {
        name: '1ml of 3% juice',
        type: VAPED,
        units: { [MILLIGRAM]: 35 }
      }
    ]
  }

  units: SubstanceUnits = {
    [TOBACCO]: {
      input: [MILLIGRAM],
      output: [MILLIGRAM_SMOKED],
      factory: units => ({ [MILLIGRAM_SMOKED]: units[MILLIGRAM] })
    },
    [SMOKED]: {
      // deprecated
      input: [MILLIGRAM],
      output: [MILLIGRAM_SMOKED],
      factory: units => ({ [MILLIGRAM_SMOKED]: units[MILLIGRAM] })
    },
    [VAPED]: {
      input: [MILLIGRAM],
      output: [MILLIGRAM_VAPED],
      factory: units => ({ [MILLIGRAM_VAPED]: units[MILLIGRAM] })
    }
  }

  stats: SubstanceStatGenerators = [
    {
      icon: Cigarette,
      id: 'nicotine-smoked',
      factory: (_, summary) =>
        summary[MILLIGRAM_SMOKED] &&
        round(summary[MILLIGRAM_SMOKED], 10) + HUMAN_UNITS[MILLIGRAM]
    },
    {
      icon: Tint,
      id: 'nicotine-vaped',
      factory: (_, summary) =>
        summary[MILLIGRAM_VAPED] &&
        round(summary[MILLIGRAM_VAPED], 10) + HUMAN_UNITS[MILLIGRAM]
    },
    timesUsedToday,
    generateTotalSpent()
  ]
}

export const timesUsedToday: SubstanceStatGenerator = {
  icon: ChevronUp,
  id: 'substance-times-used-today',
  // times used after 0:00 today
  factory: doses => {
    const today = new Date().toLocaleDateString('ca')
    return doses.filter(
      dose => new Date(dose.time).toLocaleDateString('ca') == today
    ).length
  }
}
