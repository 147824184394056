import { createSlice } from '@reduxjs/toolkit'
import { Action } from 'store/store'
import { Millis } from 'services/time'

interface ModalsState {
  modals: ShownModal[]
}

interface ShownModal {
  type: string
  created?: Millis
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any
}

export const initialState: ModalsState = {
  modals: []
}

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal (state: ModalsState, { payload }: Action<ShownModal>) {
      if (!payload.created) payload.created = Date.now()

      state.modals.push(payload)
    },
    hideModal (state: ModalsState, { payload: type }: Action<string>) {
      state.modals = state.modals.filter(m => m.type !== type)
    }
  }
})

export const { showModal, hideModal } = slice.actions
export default slice.reducer
